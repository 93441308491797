import { filterTypes } from '~/enums/FilterTypes';
import { TenantFilter, TFilterSearch } from '~/components/Filters';
export default (function () {
  var i18n = useNuxtApp().nuxt2Context.i18n;
  var availableFilters = function availableFilters() {
    return [{
      key: 'search',
      component: TFilterSearch,
      type: filterTypes.STRING,
      props: {
        label: i18n.t('global.actions.search')
      }
    }, {
      key: 'tenant_id',
      component: TenantFilter,
      type: filterTypes.ARRAY
    }];
  };
  return {
    availableFilters: availableFilters
  };
});