import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import Vue from 'vue';
import useAvailableFilters from './useAvailableFilters';
import { useFetchResources, props as fetchResourcesProps } from '~/composables/useFetchResources';
import EmptyState from '~/components/EmptyState';
import OrganizationProgressFilterCollection from '~/pages/shared/organizations-progress/partials/OrganizationProgressFilterCollection';
import UsageTypeEnum from '~/enums/UsageTypeEnum';
export default Vue.extend({
  components: {
    EmptyState: EmptyState,
    OrganizationProgressFilterCollection: OrganizationProgressFilterCollection
  },
  props: _objectSpread(_objectSpread({}, fetchResourcesProps()), {}, {
    query: {
      type: Function,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    pageSize: {
      type: Number,
      required: false,
      default: 50
    }
  }),
  setup: function setup(props) {
    var _useFetchResources = useFetchResources(props),
      fetchResources = _useFetchResources.fetchResources,
      pagination = _useFetchResources.pagination,
      resources = _useFetchResources.resources,
      loading = _useFetchResources.loading;
    return {
      fetchResources: fetchResources,
      pagination: pagination,
      resources: resources,
      loading: loading
    };
  },
  computed: {
    enabledFields: function enabledFields() {
      var _this = this;
      return this.getFields().filter(function (field) {
        return _this.fields.includes(field.key);
      });
    },
    enabledFilters: function enabledFilters() {
      var _this2 = this;
      return useAvailableFilters().availableFilters().filter(function (filter) {
        return _this2.filters.includes(filter.key);
      }).map(function (_ref) {
        var key = _ref.key,
          type = _ref.type;
        return {
          key: key,
          type: type
        };
      });
    }
  },
  methods: {
    getFields: function getFields() {
      return [{
        key: 'name',
        label: this.$t('fields.name'),
        sortable: true
      }, {
        key: 'tenant.name',
        label: this.$t('fields.tenant')
      }, {
        key: 'usage_type',
        label: this.$t('fields.usage_type')
      }, {
        key: 'employees_count',
        label: this.$t('fields.employee_count')
      }, {
        key: 'profiles_count',
        label: this.$t('fields.profiles_count')
      }, {
        key: 'email_templates_count',
        label: this.$t('fields.email_template_count')
      }, {
        key: 'invitations_count',
        label: this.$t('fields.invitations_count')
      }, {
        key: 'alerts_count',
        label: this.$t('fields.alerts_count')
      }, {
        key: 'sourced_applicants_count',
        label: this.$t('fields.sourced_applicants_count')
      }, {
        key: 'pinged_applicants_count',
        label: this.$t('fields.pinged_applicants_count')
      }];
    },
    getUsageTypeLabel: function getUsageTypeLabel(value) {
      return this.$t(UsageTypeEnum.labelKey(value));
    }
  }
});